import React, { Component } from 'react';
import CTA from '../components/CTA-newsletter';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Newsletter" keywords={['']} pathname={this.props.location.pathname} />

        <PageHeader>
          <h1>Get our newsletter</h1>
          <p>
            <strong>If you want to be kept up to date with new articles, CSS resources and tools,</strong> join our
            newsletter below.
          </p>
        </PageHeader>
        <CTA />
      </Layout>
    );
  }
}

export default Page;
